import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import { TypeB } from "components/callouts";
import productData from "components/product-data";
import Slides from "components/product-data/slides";
import './products.scss';
import imgVasopressinInjection from "images/products/img-vasopressin-injection.webp";
import bivalirudinBoxImg from "images/home/bivalirudin-box-and-vial.webp";
import dexmedBoxImg from "images/home/dexmed-pole-image.webp";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import prevduoBoxImg from "images/home/prevduo-box-and-syringe.webp";

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> Vasostrict<sup>®</sup>. Prescribing Information. Endo USA, Inc. <strong>2.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 20, 2023.<strong> 3.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed April 29, 2024. <strong>4.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 26, 2023. <strong>5.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>6.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>7.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>8.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
        </p>
    </>
)

function VasostrictInjectionPage({ pageContext }) {

    return (
        <Layout
            product="vaso"
            pageClass="products page-vasostrict"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Vasostrict® (vasopressin injection, USP)" pageDescription="Vasosctrict® ready-to-use bottles" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="Vasostrict<sup>&reg;</sup> <br class='show-desktop' />(vasopressin injection, USP)"
                        productSubTitle="Available in ready-to-use <br class='show-desktop' />(RTU) bottles"
                        productUnitsOne="20 units/100 mL in 5% dextrose"
                        productUnitsTwo="40 units/100 mL in 5% dextrose"
                        productImg={imgVasopressinInjection}
                        productImgAlt='Vasostrict ready-to-use bottles'
                        productImgHeight='630'
                        productImgWidth='626'
                        className="prd-vasostrict"
                    // productFootnote="Vasostrict<sup>®</sup> is a registered trademark of Par Pharmaceutical, Inc."
                    ></ProductHero>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col xl={4.75} lg={5} xs={12}>
                            <SlideContent
                                subTitle="Vasostrict<sup>®</sup> Product Details"
                                details="Vasostrict<sup>&reg;</sup> RTU bottles require no compounding,
                                diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>* Vasostrict<sup>&reg;</sup> RTU bottles are preservative-free, latex-free, and compatible with most automated dispensing machines." />
                        </Col>
                        <Col lg={6} xs={12}>
                            <Slides data={productData.vasopressin} />
                        </Col>
                    </Row>

                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <a
                                href="https://www.vasostrictrtu.com/workflow-scenarios/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link"
                                style={{ display: 'inline-block' }}>
                                Pharmacy Workflow Preparation
                            </a>

                            <p><a href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/774cf333-5af0-471b-bb1a-a1c19df6c1a5/774cf333-5af0-471b-bb1a-a1c19df6c1a5_source__v.pdf" target="_blank" rel="noreferrer noopener" className="pdf-link">Vasostrict<sup>&reg;</sup> Information Document</a></p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <Row justify="center">
                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Bivalirudin Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={bivalirudinBoxImg}
                                ctaImageHeight='1582'
								ctaImageWidth='2009'
                                ctaImageAlt='Bivalirudin injection ready-to-use bottles'
                                pdfLink='https://endodocuments.com/BIVALIRUDIN/PI'
                                buttonText="View Product"
                                buttonURL="/products/bivalirudin-injection"
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                className='dexmed-img'
                                ctaTitle="Dexmedetomidine Hydrochloride Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={dexmedBoxImg}
                                ctaImageHeight='551'
								ctaImageWidth='324'
                                ctaImageAlt='Dexmedetomidine Hydrochloride injection ready-to-use bottles'
                                pdfLink='https://endodocuments.com/dexmedetomidine/PI'
                                buttonText="View Product"
                                buttonURL="/products/dexmedetomidine-hcl"
                                captionClass='img-caption__right'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageHeight='160'
								ctaImageWidth='194'
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/EPHEDRINE_5/PI'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="PREVDUO<sup>&reg;</sup> (neostigmine  methylsulfate and  glycopyrrolate) Injection"
                                ctaSubTitle="Prefilled syringes"
                                ctaImage={prevduoBoxImg}
                                ctaImageHeight='212'
								ctaImageWidth='284'
                                ctaImageAlt='Prevduo carton and prefilled syringe'
                                pdfLink='https://endodocuments.com/PREVDUO/PI'
                                buttonText="View Product"
                                buttonURL="/products/prevduo-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>3</sup> versus shelf life of 2 years at CRT for Dexmedetomidine, Ephedrine, and PREVDUO<sup>®</sup><sup>4-6</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®2</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,8</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default VasostrictInjectionPage;